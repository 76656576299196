import React, { createContext, useContext, useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
    const { publicKey, connected } = useWallet();
    const [walletStatus, setWalletStatus] = useState({
        publicKey: null,
        connected: false,
    });

    useEffect(() => {
        // console.log('WalletContext: Updating wallet status:', { publicKey, connected });
        setWalletStatus({ publicKey, connected });
    }, [publicKey, connected]);

    return (
        <WalletContext.Provider value={walletStatus}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWalletStatus = () => {
    return useContext(WalletContext);
};
