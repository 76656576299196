import React, { useEffect, useMemo } from 'react';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { TorusWalletAdapter } from '@solana/wallet-adapter-torus';
import { TrustWalletAdapter } from '@solana/wallet-adapter-trust';
import { CoinbaseWalletAdapter } from '@solana/wallet-adapter-coinbase';
import { clusterApiUrl } from '@solana/web3.js';

import '@solana/wallet-adapter-react-ui/styles.css';

const WalletConnectionProvider = ({ children }) => {
    const endpoint = useMemo(() => clusterApiUrl('mainnet-beta'), []);
    const wallets = useMemo(() => [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new TorusWalletAdapter(),
        new TrustWalletAdapter(),
        new CoinbaseWalletAdapter(),
    ], []);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const WalletButton = () => {
    const { publicKey, connected } = useWallet();

    useEffect(() => {
        // console.log('WalletButton: Connection state changed:', connected);
        console.log('Wallet connected:', publicKey?.toString());

        if (connected && publicKey) {
            const walletAddress = publicKey.toString();
            // console.log('WalletButton: Wallet connected:', walletAddress);
            const event = new CustomEvent('Yo-Ho-Ho! wallet connected! ', { detail: { walletAddress } });
            window.dispatchEvent(event);
        } else {
            console.log('Wallet disconnected! Godamn! Connect it back yo scumbag !');
            const event = new CustomEvent('wallet-disconnected');
            window.dispatchEvent(event);
        }
    }, [connected, publicKey]);

    return (
        <div>
            <WalletMultiButton />
        </div>
    );
};

const WalletApp = () => (
    <WalletConnectionProvider>
        <WalletButton />
    </WalletConnectionProvider>
);

export default WalletApp;
