import React, { useState, useEffect, useCallback } from 'react';
import { ConnectionProvider, WalletProvider as SolanaWalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { 
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
    Coin98WalletAdapter,
    LedgerWalletAdapter,
    MathWalletAdapter,
    CloverWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { WalletProvider } from './connection/WalletContext'; // Importar o WalletProvider do WalletContext
import Purchase from './purchase';
import '@solana/wallet-adapter-react-ui/styles.css';

const App = () => {
    const [network, setNetwork] = useState('');  // Estado para armazenar o network

    // Função para buscar o network do backend
    const fetchNetwork = useCallback(async () => {
        try {
            const response = await fetch('https://catsparrow.online/wp-json/catspw/v1/config', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });
            const data = await response.json();
            setNetwork(data.network);  // Atualizando o estado com o network vindo do backend
        } catch (error) {
            console.error('Erro ao buscar a configuração de network:', error);
        }
    }, []);

    useEffect(() => {
        fetchNetwork();  // Chamando a função para buscar o network ao montar o componente
    }, [fetchNetwork]);

    const wallets = [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new TorusWalletAdapter(),
        new Coin98WalletAdapter(),
        new LedgerWalletAdapter(),
        new MathWalletAdapter(),
        new CloverWalletAdapter(),
    ];

    // Verifique se há uma API Key e use-a se disponível
    const endpoint = network === 'mainnet' 
        ? process.env.REACT_APP_MAINNET_ENDPOINT || 'https://api.mainnet-beta.solana.com'
        : process.env.REACT_APP_TESTNET_ENDPOINT || 'https://api.testnet.solana.com';

    // Só renderizar o app quando o network estiver carregado
    if (!network) {
        return <div>Loading...</div>;
    }

    return (
        <ConnectionProvider endpoint={endpoint}>
            <SolanaWalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <WalletProvider> {/* Envolvendo o componente Purchase com o WalletProvider */}
                        <Purchase />  {/* Renderizando apenas o componente Purchase */}
                    </WalletProvider>
                </WalletModalProvider>
            </SolanaWalletProvider>
        </ConnectionProvider>
    );
};

export default App;
