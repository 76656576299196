import React, { useState, useEffect, useCallback } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import {
    PublicKey,
    LAMPORTS_PER_SOL,
    Connection,
    Transaction,
    SystemProgram,
} from '@solana/web3.js';
import {
    getAccount,
    getAssociatedTokenAddress,
    createAssociatedTokenAccountInstruction,
} from '@solana/spl-token';
import './purchase.css';

const Purchase = () => {
    const { connected, publicKey, signTransaction } = useWallet();
    const [solAmount, setSolAmount] = useState('');
    const [catspwAmount, setCatspwAmount] = useState('');
    const [transactionSignature, setTransactionSignature] = useState('');
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState(null);
    const [connection, setConnection] = useState(null);
    const [error, setError] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showMinAmountPopup, setShowMinAmountPopup] = useState(false);
    const [treasureBalance, setTreasureBalance] = useState(0);
    const [showTransactionConfirmation, setShowTransactionConfirmation] = useState(false);

    // Função para fechar popups
    const handlePopupClose = () => {
        setShowSuccessPopup(false);
        setShowMinAmountPopup(false);
        setError(null);
        setShowTransactionConfirmation(false);
    };

    // Obter o blockhash recente
    const getRecentBlockhash = async (connection) => {
        try {
            const { blockhash } = await connection.getLatestBlockhash();
            return blockhash;
        } catch (error) {
            console.error('Error fetching recent blockhash:', error);
            throw new Error('Failed to fetch recent blockhash');
        }
    };

    // Função para obter o saldo de CATSPW na carteira Treasure
    const getTokenBalance = async (connection, treasuryPublicKey, tokenMintPublicKey) => {
        try {
            const accounts = await connection.getParsedTokenAccountsByOwner(treasuryPublicKey, {
                mint: tokenMintPublicKey,
            });

            if (accounts.value.length > 0) {
                const balance =
                    accounts.value[0].account.data.parsed.info.tokenAmount.uiAmount;
                return balance;
            } else {
                throw new Error('No token accounts found for this wallet.');
            }
        } catch (error) {
            console.error('Error fetching token balance:', error);
            throw new Error('Failed to fetch CATSPW balance.');
        }
    };

    // Obter a configuração inicial do contrato e saldo do tesouro
    const fetchConfig = useCallback(async () => {
        try {
            const response = await fetch(
                'https://catsparrow.online/wp-json/catspw/v1/config',
                {
                    method: 'GET',
                    headers: { Accept: 'application/json' },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setConfig(data);
            setSolAmount(parseFloat(data.min_purchase_amount));

            // Inicializar conexão usando quicknode_url da configuração
            const newConnection = new Connection(data.quicknode_url, 'confirmed');
            setConnection(newConnection);

            const treasuryPublicKey = new PublicKey(data.wallet_address);
            const tokenMintPublicKey = new PublicKey(data.contract_address);

            const catspwBalance = await getTokenBalance(
                newConnection,
                treasuryPublicKey,
                tokenMintPublicKey
            );

            setTreasureBalance(catspwBalance);
        } catch (error) {
            console.error('Error fetching configuration:', error);
            setError('Failed to load configuration. Please try again.');
        }
    }, []);

    useEffect(() => {
        fetchConfig();
    }, [fetchConfig]);

    useEffect(() => {
        if (config) {
            const calculatedCatspwAmount = (
                solAmount * parseFloat(config.catspw_value)
            ).toFixed(0);
            let finalAmount = calculatedCatspwAmount;

            if (config.bonus_type === 'percent') {
                finalAmount = (
                    parseFloat(calculatedCatspwAmount) *
                    (1 + parseFloat(config.bonus_amount) / 100)
                ).toFixed(0);
            } else if (config.bonus_type === 'tokens') {
                finalAmount = (
                    parseFloat(calculatedCatspwAmount) + parseFloat(config.bonus_amount)
                ).toFixed(0);
            }

            setCatspwAmount(finalAmount);
        }
    }, [solAmount, config]);

    // Função para validar a transferência de CATSPW antes de transferir SOL
    const validateCatspwTransfer = async () => {
        try {
            const response = await fetch(
                'https://catsparrow.online/wp-json/catspw/v1/validate-catspw-transfer',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userPublicKey: publicKey.toBase58(),
                        catspwAmount: parseInt(catspwAmount),
                    }),
                }
            );
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message || 'Validation failed.');
            }
            return true;
        } catch (error) {
            console.error('Validation failed:', error);
            setError(`Validation failed: ${error.message}`);
            return false;
        }
    };

    // Função que lida com a confirmação da transação
    const confirmTransaction = useCallback(async () => {
        if (!connection) {
            setError('Connection not initialized. Please try again.');
            return;
        }

        if (parseFloat(solAmount) < parseFloat(config.min_purchase_amount)) {
            setShowMinAmountPopup(true);
            return;
        }

        setLoading(true);
        setError(null);
        setShowTransactionConfirmation(false);

        try {
            if (!publicKey) {
                throw new Error('Public key is undefined. Wallet not connected!');
            }

            // Validar transferência de CATSPW
            const isValid = await validateCatspwTransfer();
            if (!isValid) {
                setLoading(false);
                return;
            }

            const tokenMintPublicKey = new PublicKey(config.contract_address);
            const treasuryWalletPublicKey = new PublicKey(config.wallet_address);

            console.log("Checkin' and creatin' user's associated token account...");

            const userAssociatedTokenAccount = await getAssociatedTokenAddress(
                tokenMintPublicKey,
                publicKey
            );

            let transaction = new Transaction();

            try {
                await getAccount(connection, userAssociatedTokenAccount);
                console.log("User's associated token account already exists.");
            } catch (error) {
                console.log("User's associated token account doesn't exist. Creatin'...");
                const createATAInstruction = createAssociatedTokenAccountInstruction(
                    publicKey, // Payer
                    userAssociatedTokenAccount, // ATA to create
                    publicKey, // Owner of the ATA
                    tokenMintPublicKey // Token mint
                );
                transaction.add(createATAInstruction);
            }

            console.log(
                "Askin' wallet to sign the creation of associated token account (if needed) and SOL transfer..."
            );

            // Adicionar instrução de transferência de SOL
            const solTransferInstruction = SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: treasuryWalletPublicKey,
                lamports: solAmount * LAMPORTS_PER_SOL,
            });

            transaction.add(solTransferInstruction);

            // Configurar transação
            const blockhash = await getRecentBlockhash(connection);
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = publicKey;

            // Solicitar assinatura
            const signedTransaction = await signTransaction(transaction);
            if (!signedTransaction) {
                throw new Error('Transaction was not signed. User may have canceled.');
            }

            // Enviar transação
            const signature = await connection.sendRawTransaction(
                signedTransaction.serialize()
            );
            await connection.confirmTransaction(signature, 'confirmed');

            console.log('SOL transfer completed.');

            // Enviar requisição ao backend para transferência de CATSPW
            const response = await fetch(
                'https://catsparrow.online/wp-json/catspw/v1/transfer-catspw',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // Adicione autenticação se necessário
                    },
                    body: JSON.stringify({
                        solTransferSignature: signature,
                        userPublicKey: publicKey.toBase58(),
                        catspwAmount: parseInt(catspwAmount),
                    }),
                }
            );

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.message || 'Error transferring CATSPW.');
            }

            console.log('CATSPW tokens transferred!');

            setTransactionSignature(signature);
            setShowSuccessPopup(true);
            await fetchConfig();
        } catch (error) {
            console.error('Transaction failed:', error);
            setError(`Transaction failed: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, [
        connection,
        solAmount,
        catspwAmount,
        publicKey,
        config,
        signTransaction,
        fetchConfig,
    ]);

    if (!connected) {
        return (
            <div className="wallet-disconnected">
                <div
                    style={{
                        textAlign: 'center',
                        color: '#dd9933',
                        fontSize: '20px',
                        fontFamily: 'Rocknroll One, sans-serif',
                        marginTop: '20px',
                        animation: 'blink 1s step-start infinite',
                    }}
                >
                    Connect yer wallet and reload the page!<br />
                </div>
                <button
                    onClick={() => window.location.reload()}
                    className="swap-button"
                >
                    Reload page
                </button>
            </div>
        );
    }

    if (!config || !connection) {
        return <div>Loading configuration and initializing connection...</div>;
    }

    return (
        <div id="purchase">
            <div>
                <label className="purchase-label">
                    Amount in $SOL:
                    <input
                        type="number"
                        className="purchase-input"
                        value={solAmount}
                        onChange={(e) => setSolAmount(e.target.value)}
                        min={config.min_purchase_amount}
                        step="0.0001"
                    />
                </label>
            </div>
            <div>
                <label className="purchase-label">
                    + Bonus:
                    <input
                        type="text"
                        className="purchase-input"
                        value={`${
                            config.bonus_type === 'percent'
                                ? config.bonus_amount + '%'
                                : config.bonus_amount + ' Tokens'
                        }`}
                        readOnly
                    />
                </label>
            </div>
            <div>
                <label className="purchase-label">
                    Amount ye'll receive in $CATSPW:
                    <input
                        type="number"
                        className="purchase-input"
                        value={catspwAmount}
                        readOnly
                    />
                </label>
            </div>
            <button
                onClick={() => setShowTransactionConfirmation(true)}
                disabled={loading}
                className="purchase-button"
            >
                {loading ? (
                    <span>
                        <span className="spinner"></span> Processing transaction
                    </span>
                ) : (
                    'Purrrchase'
                )}
            </button>
            {error && <div className="error-message">{error}</div>}

            {showSuccessPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>
                            Ahoy! Transaction be successful! Ye now own{' '}
                            {catspwAmount} $CATSPW from our treasure!
                        </p>
                        <p>
                            <a
                                href={`https://explorer.solana.com/tx/${transactionSignature}?cluster=${
                                    config.network === 'mainnet' ? 'mainnet-beta' : 'testnet'
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View yer plunder on Solana Explorer
                            </a>
                        </p>
                        <button
                            onClick={handlePopupClose}
                            className="close-popup-button"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            {showMinAmountPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>
                            Minimum purchase amount is {config.min_purchase_amount} $SOL.
                            Please increase yer amount.
                        </p>
                        <button
                            onClick={handlePopupClose}
                            className="close-popup-button"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            {showTransactionConfirmation && (
                <div className="popup">
                    <div className="popup-content">
                        <p>
                            Ye are about to purchase {catspwAmount} $CATSPW. A total
                            of {solAmount} $SOL will be debited from yer wallet.
                        </p>
                        <button
                            onClick={confirmTransaction}
                            className="confirm-button"
                        >
                            Confirm
                        </button>
                        <button
                            onClick={() => setShowTransactionConfirmation(false)}
                            className="close-popup-button"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}

            <div
                style={{
                    textAlign: 'center',
                    color: '#dd9933',
                    fontSize: '20px',
                    fontFamily: 'Rocknroll One, sans-serif',
                    marginTop: '20px',
                    animation: 'blink 1s step-start infinite',
                }}
            >
                Hurry UP Matey!
                <br />
                There's only {treasureBalance.toLocaleString()}
                <br />
                $CATSPW available for this Pre-sale!!!
            </div>
        </div>
    );
};

export default Purchase;
