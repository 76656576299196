import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import WalletButton from './connection/WalletButton'; // Certifique-se de que o caminho está correto
import reportWebVitals from './reportWebVitals';

// A constante 'purchase'  criada para apontar para a div 'purchase'
const purchase = ReactDOM.createRoot(document.getElementById('purchase'));

// A constante 'walletButton' é criada para apontar para a div 'wallet-button-root'
const walletButton = ReactDOM.createRoot(document.getElementById('wallet-button-root'));

// Renderizando o App (formulário de swap) dentro da div 'purchase'
purchase.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Renderizando o WalletButton (botão de conexo) dentro da div 'wallet-button-root'
walletButton.render(
  <React.StrictMode>
    <WalletButton />
  </React.StrictMode>
);

// Se você quiser começar a medir o desempenho da sua aplicação, passe uma função
// para registrar os resultados (por exemplo: reportWebVitals(console.log))
// ou envie para um endpoint de análise. Saiba mais: https://bit.ly/CRA-vitals
reportWebVitals();
